import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MenuState {
  categories: KategoriMenu[];
  item?: ItemMenu | null;
  items: ItemMenu[];
}

const initialState: MenuState = {
  categories: [],
  items: [],
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setCategories: (state, { payload }: PayloadAction<KategoriMenu[]>) => {
      state.categories = payload;
    },
    setItem: (state, { payload }: PayloadAction<ItemMenu | null | undefined>) => {
      state.item = payload;
    },
    setItems: (state, { payload }: PayloadAction<ItemMenu[]>) => {
      state.items = payload;
    },
  },
});

export const { setCategories, setItem, setItems } = menuSlice.actions;
export default menuSlice.reducer;
