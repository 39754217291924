import { Stack, Typography } from '@mui/joy';
import { Blur } from 'iconsax-react';

interface Props {
  size?: 'md' | 'lg';
}

const Logo: React.FC<Props> = ({ size }) => {
  return (
    <Stack direction="row" alignItems="center">
      <Blur
        color="#ff6f00"
        size={size === 'lg' ? 32 : undefined}
        variant="Bulk"
        style={{ transform: 'rotate(15deg)' }}
      />
      <Typography
        level="title-lg"
        sx={{ color: '#ff6f00' }}
        fontSize={size === 'lg' ? 20 : undefined}
      >
          etalaseu
      </Typography>
    </Stack>
  );
};

export default Logo;
