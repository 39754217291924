import axios, { AxiosResponse } from 'axios';
import { API_BASE_URL } from '../constants';
import { getBasicHeader, getBearerHeader } from '../utils/services';

function signIn(email: string, password: string): Promise<AxiosResponse> {
  const url = `${API_BASE_URL}/sign-in`;
  const token = window.btoa(`${email}:${password}`);

  return axios.post(url, {}, { headers: getBasicHeader(token) });
}

function getAll(token: string, limit: number, page: number, sortBy?: string, order?: SortOrder, search?: string): Promise<AxiosResponse> {
  let url = `${API_BASE_URL}/user?limit=${limit}&page=${page}`;
  if (sortBy != null) {
    url += `&sort=${sortBy}`;
  }

  if (order != null) {
    url += `&order=${order}`;
  }

  if (search != null) {
    url += `&q=${search}`;
  }

  return axios.get(url, { headers: getBearerHeader(token) });
}

function getById(token: string, id: number): Promise<AxiosResponse> {
  const url = `${API_BASE_URL}/user/${id}`;
  return axios.get(url, { headers: getBearerHeader(token) });
}

function insert(token: string, user: User, image?: File): Promise<AxiosResponse> {
  const url = `${API_BASE_URL}/user`;

  const form = new FormData();
  form.append('data', JSON.stringify(user));

  if (image != null) {
    form.append('image', image);
  }

  return axios.post(url, form, { headers: getBearerHeader(token) });
}

function update(token: string, user: User, image?: File): Promise<AxiosResponse> {
  const url = `${API_BASE_URL}/user/${user.id}`;

  const form = new FormData();
  form.append('data', JSON.stringify(user));

  if (image != null) {
    form.append('image', image);
  }

  return axios.put(url, form, { headers: getBearerHeader(token) });
}

function remove(token: string, id: number) {
  const url = `${API_BASE_URL}/user/${id}`;
  return axios.delete(url, { headers: getBearerHeader(token) });
}


const UsersService = {
  signIn,
  getAll,
  getById,
  insert,
  remove,
  update,
};

export default UsersService;
