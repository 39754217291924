import { setIsLoggedIn, setToken, setUser } from '.';
import { AppThunk } from '..';
import UsersService from '../../services/users';
import { clearLocalStorage, saveToken, saveUser } from '../../utils/localstorage';
import { setLoading, showToast } from '../ui';

type OnSignIn = () => void;
type OnSignOut = () => void;

export function signIn(email: string, password: string, persistent: boolean, onSignIn?: OnSignIn): AppThunk {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const { data } = await UsersService.signIn(email, password);
      const userData = data.data;

      const user: UserProfile = {
        email: userData.email,
        id: userData.email,
        pic: userData.pic,
        fullname: userData.fullname,
      };
  
      const token = userData.token;
  
      if (persistent) {
        saveUser(user);
        saveToken(token);
      }
  
      dispatch(setUser(user));
      dispatch(setToken(token));
      dispatch(setIsLoggedIn(true));
  
      if (typeof onSignIn === 'function') {
        onSignIn();
      }

    } catch (err: any) {
      if (err.response != null && err.response.data != null && err.response.data.message != null) {
        dispatch(showToast({ message: err.response.data.message, color: 'danger' }));
      } else {
        dispatch(showToast({ message: err.message, color: 'danger' }));
      }
    }
    dispatch(setLoading(false));
  };
}

export function signOut(onSignOut?: OnSignOut): AppThunk {
  return (dispatch) => {
    dispatch(setUser(null));
    dispatch(setIsLoggedIn(false));

    clearLocalStorage();
    
    if (typeof onSignOut === 'function') {
      onSignOut();
    }
  };
}
