import { Star, TaskSquare, VideoPlay } from 'iconsax-react';
import { IconVariant } from '.';

export const menuSocial = (variant: IconVariant, size: number): NavMenu[] => {
  return [
    {
      caption: 'Social Media',
      type: 'group',
      children: [
        {
          caption: 'Content',
          icon: <VideoPlay variant={variant} size={size} />,
        },
        {
          caption: 'Tugas',
          icon: <TaskSquare variant={variant} size={size} />,
        },
        {
          caption: 'Rating',
          icon: <Star variant={variant} size={size} />,
        },
      ],
    },
  ];
};
