import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserState {
  users: User[],
  userCount: number;
  user?: User | null;
}

const initialState: UserState = {
  users: [],
  userCount: 0,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload }: PayloadAction<User | null | undefined>) => {
      state.user = payload;
    },
    setUserCount: (state, { payload }: PayloadAction<number>) => {
      state.userCount = payload;
    },
    setUsers: (state, { payload }: PayloadAction<User[]>) => {
      state.users = payload;
    },
  }
})

export const { setUser, setUserCount, setUsers } = userSlice.actions;
export default userSlice.reducer;